import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DatePickerField from "components/Form/DatePickerFieldWithYear/DatePickerFieldWithYear";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalState } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { createPackage, updatePackage } from "services/package";
import { mapCurrencyToSelect } from ".";

export const PACKAGE_TYPES = [
	{ value: "add", label: "Add", id: 0 },
	{ value: "deduct", label: "Deduct", id: 0 },
	{ value: "transfer", label: "Transfer", id: 0 },
	{ value: "gift", label: "Gift", id: 0 },
];

const REQUIRED_FIELDS = [
	"safa_key",
	"package",
	"contract",
	"package_type",
	"package_price",
	"currency",
	"start_date",
	"end_date",
	"visa_count",
];

function isLastDayInMonth(date) {
	const currentDay = moment(date).format("DD");
	const lastDay = moment(date).endOf("month").format("DD");

	// if (currentDay === "Invalid date") {
	// 	throw new Error("Invalid Date");
	// }
	return currentDay === lastDay;
}

function excludeField(fields, field) {
	if (Array.isArray(field)) {
		return fields.filter((f) => !field.includes(f));
	}

	return fields.filter((f) => f !== field);
}

export default function AddEditPackageModal({
	setAddEditModal,
	addEditModal,
	fetchPackages,
	safaKeys,
	setSafaKeys,
	getSafaKeys,
	setAddPaymentModal,
	addEditPaymentModal,
}) {
	const [visibleMonth, setVisibleMonth] = useState(false);
	const { allCurrencies, permissions } = useGlobalState();

	const [errors, setErrors] = useState({});

	const isGiftPackage = addEditModal?.data?.package_type?.value === "gift";
	const isHasPermissionToAddPayment = permissions?.includes(
		"SafaVisa-Package-Payment-Add"
	);

	const handleOnChange = (key, value) => {
		setErrors({
			...errors,
			...validate(
				{
					name: key,
					value,
				},
				{
					required: true,
					minNumber:
						key === "package_price" ? addEditModal.data?.amount_pay : null,
				}
			),
		});

		setAddEditModal({
			...addEditModal,
			data: {
				...addEditModal.data,
				[key]: value,
			},
		});
	};

	const addPackage = async () => {
		const formData = new FormData();
		formData.append("package", addEditModal.data.package);
		formData.append("company_safa_key_id", addEditModal.data?.safa_key?.id);
		formData.append("contract", addEditModal.data.contract);
		formData.append(
			"package_price",
			!isGiftPackage ? addEditModal.data.package_price : 0
		);
		!isGiftPackage &&
			formData.append("currency", addEditModal.data.currency.id);
		formData.append(
			"start_date",
			moment(addEditModal.data.start_date).format("YYYY-MM-DD")
		);
		formData.append(
			"end_date",
			moment(addEditModal.data.end_date).format("YYYY-MM-DD")
		);
		formData.append("package_type", addEditModal.data.package_type.value);
		formData.append("visa_count", addEditModal.data.visa_count);

		const res = await createPackage(formData);
		!isGiftPackage &&
			isHasPermissionToAddPayment &&
			setAddPaymentModal({
				open: true,
				type: "add",
				data: {
					...addEditPaymentModal?.data,
					safa_visa_package_id: res?.data?.data?.id,
					company_name: res?.data?.data?.company,
					package_price: res?.data?.data?.package_price,
					amount_pay: res?.data?.data?.amount_pay,
				},
			});
	};

	const editPackage = async () => {
		const formData = new FormData();
		formData.append("_method", "put");
		formData.append("package", addEditModal.data.package);
		formData.append("company_safa_key_id", addEditModal.data?.safa_key?.id);
		formData.append("contract", addEditModal.data.contract);
		!isGiftPackage &&
			formData.append("package_price", addEditModal.data.package_price);
		!isGiftPackage &&
			formData.append("currency", addEditModal.data.currency.id);
		formData.append(
			"start_date",
			moment(addEditModal.data.start_date).format("YYYY-MM-DD")
		);
		formData.append(
			"end_date",
			moment(addEditModal.data.end_date).format("YYYY-MM-DD")
		);
		formData.append("package_type", addEditModal.data.package_type.value);
		formData.append("visa_count", addEditModal.data.visa_count);

		await updatePackage(addEditModal.data?.id, formData);
	};

	const checkFormErrors = () => {
		let submitErrors = {};
		Object.keys(addEditModal.data).forEach((key) => {
			const requiredFields =
				addEditModal?.data?.package_type?.value === "gift"
					? excludeField(REQUIRED_FIELDS, ["package_price", "currency"])
					: REQUIRED_FIELDS;

			if (requiredFields.includes(key)) {
				submitErrors = {
					...submitErrors,
					...validate(
						{
							name: key,
							value: addEditModal.data[key],
						},
						{
							required: true,
							minNumber:
								key === "package_price" ? addEditModal.data?.amount_pay : null,
						}
					),
				};
			}
		});
		setErrors(submitErrors);
		return submitErrors;
	};

	const toggleAddEditModal = (type) => {
		setAddEditModal({
			...addEditModal,
			open: !addEditModal.open,
			type,
			data: {
				safa_key: null,
				package: "",
				contract: "",
				package_price: "",
				currency: null,
				start_date: null,
				end_date: null,
			},
		});
		setErrors({});
	};

	const submit = async () => {
		const formErrors = checkFormErrors();
		if (isFormValid(formErrors)) {
			if (addEditModal.type === "add") {
				await addPackage();
			} else {
				await editPackage();
			}

			fetchPackages();
			toggleAddEditModal(addEditModal.type);
		}
	};

	useEffect(() => {
		if (addEditModal.open) {
			setErrors({});
		}
	}, [addEditModal.open]);

	return (
		<Modal
			isOpen={addEditModal.open}
			toggle={() => toggleAddEditModal(addEditModal.type)}
		>
			<ModalHeader toggle={() => toggleAddEditModal(addEditModal.type)}>
				{addEditModal.type === "add" ? "Add Package" : "Edit Package"}
			</ModalHeader>

			<ModalBody>
				<div className="row align-items-start">
					<div className="col-6">
						<AutoCompleteField
							label="Safa Key"
							placeholder="Safa Key"
							name="safa_key"
							id="safa_key"
							listAuto={safaKeys}
							setListAuto={setSafaKeys}
							getListAuto={getSafaKeys}
							isSearchable={true}
							value={addEditModal.data?.safa_key?.safa_key}
							onChange={(e) => {
								handleOnChange("safa_key", e);
							}}
							onSelectValue={(e) => {
								handleOnChange("safa_key", e);
							}}
							color={errors?.safa_key?.required ? "danger" : ""}
							errors={errors?.safa_key}
						/>
					</div>
					<div className="col-6">
						<TextField
							label="Country"
							type="text"
							placeholder="Country"
							name="country"
							id="country"
							value={addEditModal.data?.safa_key?.country}
							disabled
						/>
					</div>
					<div className="col-12">
						<TextField
							label="Company Name"
							type="text"
							placeholder="Company Name"
							name="company"
							id="company"
							value={addEditModal.data?.safa_key?.company_name}
							disabled
						/>
					</div>
					<div className="col-6">
						<TextField
							label="Package Name"
							type="text"
							placeholder="Package Name"
							name="package"
							id="package"
							value={addEditModal.data.package}
							onChange={(e) => {
								handleOnChange("package", e.target.value);
							}}
							color={errors?.package?.required ? "danger" : ""}
							errors={errors?.package}
						/>
					</div>
					<div className="col-6">
						<TextField
							label="Contract"
							type="number"
							placeholder="Contract"
							name="contract"
							id="contract"
							value={addEditModal.data.contract}
							onChange={(e) => {
								handleOnChange("contract", e.target.value);
							}}
							color={errors?.contract?.required ? "danger" : ""}
							errors={errors?.contract}
						/>
					</div>
					<div className="col-12">
						{/* visa its a number */}
						<TextField
							label="Visa Count"
							type="number"
							placeholder="Visa Count"
							name="visa_count"
							id="visa_count"
							value={addEditModal.data.visa_count}
							onChange={(e) => {
								handleOnChange("visa_count", e.target.value);
							}}
							color={errors?.visa_count?.required ? "danger" : ""}
							errors={errors?.visa_count}
						/>
					</div>
					<div className="col-12">
						<SelectField
							label="Package Type"
							placeholder="Package Type"
							name="package_type"
							id="package_type"
							options={PACKAGE_TYPES}
							value={addEditModal.data.package_type}
							onChange={(e) => {
								handleOnChange("package_type", e);
								if (e.value === "gift") {
									setAddEditModal({
										...addEditModal,
										data: {
											...addEditModal.data,
											package_type: e,
											package_price: 0,
										},
									});
								}

								setErrors({
									...errors,
									...validate(
										{
											name: "package_type",
											value: e,
										},
										{ required: true }
									),
								});
							}}
							color={errors?.package_type?.required ? "danger" : ""}
							errors={errors?.package_type}
						/>
					</div>
					{addEditModal.data?.package_type?.value !== "gift" && (
						<div className="col-6">
							<TextField
								label="Package Price"
								type="number"
								placeholder="Package Price"
								name="package_price"
								id="package_price"
								value={addEditModal.data.package_price}
								min={addEditModal.data?.amount_pay}
								onChange={(e) => {
									handleOnChange("package_price", e.target.value);
								}}
								color={errors?.package_price?.required ? "danger" : ""}
								errors={errors?.package_price}
							/>
						</div>
					)}
					{addEditModal.data?.package_type?.value !== "gift" && (
						<div className="col-6">
							<SelectField
								label="Currency"
								placeholder="Currency"
								name="currency"
								id="currency"
								options={mapCurrencyToSelect(allCurrencies)}
								value={addEditModal.data.currency}
								onChange={(e) => {
									handleOnChange("currency", e);
								}}
								color={errors?.currency?.required ? "danger" : ""}
								errors={errors?.currency}
							/>
						</div>
					)}
					<div className="col-6">
						<DatePickerField
							type="text"
							placeholder={"DD/MM/YYYY"}
							label={"Start Date"}
							hasYears={true} // Enable year selection
							date={addEditModal.data.start_date}
							initialDate={addEditModal.data.start_date || moment("2024-06-01")}
							minDate={moment("2024-06-01")}
							initialVisibleMonth={() =>
								addEditModal.data.start_date || moment("2024-06-01")
							}
							onChangeDate={(e) => {
								setAddEditModal({
									...addEditModal,
									data: {
										...addEditModal.data,
										start_date: e,
										end_date: null,
									},
								});

								if (e) {
									if (isLastDayInMonth(e)) {
										setVisibleMonth(moment(e).add(1, "day"));
									} else {
										setVisibleMonth(moment(e));
									}
								}

								setErrors({
									...errors,
									...validate(
										{
											name: "start_date",
											value: e,
										},
										{ required: true, date: true }
									),
								});
							}}
							color={errors?.start_date?.required ? "danger" : ""}
							errors={errors?.start_date}
						/>
					</div>
					<div className="col-6">
						<DatePickerField
							type="text"
							placeholder={"DD/MM/YYYY"}
							label={"End Date"}
							date={addEditModal.data.end_date}
							minDate={addEditModal.data.start_date || moment("2024-06-01")}
							hasYears={true} // Enable year selection
							onChangeDate={(e) => {
								console.log("end_date", e);
								handleOnChange("end_date", e);
								setErrors({
									...errors,
									...validate(
										{
											name: "end_date",
											value: e,
										},
										{ required: true }
									),
								});
							}}
							month={moment(new Date())}
							initialVisibleMonth={() =>
								visibleMonth ||
								moment(addEditModal.data.start_date || moment("2024-06-01"))
							}
							color={errors?.end_date?.required ? "danger" : ""}
							errors={errors?.end_date}
						/>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<button
					className="btn btn-secondary"
					onClick={() => toggleAddEditModal(addEditModal.type)}
				>
					Cancel
				</button>
				<button className="btn btn-primary" onClick={submit}>
					Save
				</button>
			</ModalFooter>
		</Modal>
	);
}
